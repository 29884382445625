import React, { useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import './style.css'
import NavBar from '../common/navbar'
import Table from 'react-bootstrap/Table'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'

function UserList(props) {
    let isAuth = window.localStorage.getItem("isAuth") ? window.localStorage.getItem("isAuth") === "true" : window.sessionStorage.getItem("isAuth") === "true"
    let isAdmin = window.sessionStorage.getItem("isAdmin")
    let tableData = useSelector(state => state.usersList)
    let dispatch = useDispatch()

    useEffect(() => {
        async function getUsersList() {
            await fetch("/GetUsersList")
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.status === "Success") {
                            dispatch(Actions.setUsersList(result.data))
                            tableData = result.data
                        } else {
                            dispatch(Actions.setUsersList([]))
                            tableData = []
                        }
                    },
                    (error) => {
                        dispatch(Actions.setUsersList([]))
                        tableData = []
                    }
                )
        }

        if (isAuth && isAdmin) {
            getUsersList()
        }
    }, [])

    if (!isAdmin) {
        return (<Redirect to="/home" />)
    }

    if (!isAuth) {
        return (<Redirect to="/" />)
    }

    const editUser = (e) => {
        let user = tableData[+e.target.parentNode.parentNode.parentNode.id.replace("userRow", "")]
        let id = user.id
        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
        props.history.push("/users/edit/" + id, { id: id })
    }

    const deleteUser = async (e) => {
        let user = tableData[+e.target.parentNode.parentNode.parentNode.id.replace("userRow", "")]
        let currentUserId = window.localStorage.getItem("userId") || window.sessionStorage.getItem("userId")
        let id = user.id
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id })
        }

        let successMessage = document.getElementById("successUserDeleting")
        let errorMessage = document.getElementById("errorUserDeleting")

        await fetch("/DeleteUser", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        successMessage.classList.remove("resultSpan")
                        errorMessage.classList.add("resultSpan")
                        if (currentUserId === id) {
                            window.localStorage.clear()
                            window.sessionStorage.clear()
                            props.history.push("/")
                        }
                    } else {
                        errorMessage.classList.remove("resultSpan")
                        successMessage.classList.add("resultSpan")
                    }
                    setTimeout(() => { window.location.reload() }, 1000)
                },
                (error) => {
                    errorMessage.classList.remove("resultSpan")
                    successMessage.classList.add("resultSpan")
                }
            )
    }

    const onCreateNewUser = () => {
        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
        props.history.push("/users/new")
    }

    let table = tableData.map((item, index) =>
        <tr key={index} id={"userRow" + index}>
            <td>{item.username}</td>
            <td>{item.email}</td>
            <td>{item.isAdmin}</td>
            <td>{item.ecoCentre}</td>
            <td>
                <div className="btn-group">
                    <button className="btn btn-primary" onClick={editUser}>Modifier</button>
                    <button className="btn btn-primary left-border" onClick={deleteUser}>Supprimer</button>
                </div>
            </td>
        </tr>
    )

    return (
        <div>
            <NavBar></NavBar>
            <div className="munResultMainDiv margin-bottom">
                <span className="alert alert-success resultSpan adaptiveSpan" id="successUserDeleting" role="alert">
                    Succès!
                    </span>
                <span className="alert alert-danger resultSpan adaptiveSpan" id="errorUserDeleting" role="alert">
                    Error!
                    </span>
            </div>
            <button className="create-user-button-margins btn btn-primary" onClick={onCreateNewUser}>Nouveau</button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Utilisateur</th>
                        <th>Courriel</th>
                        <th>Administrateur</th>
                        <th>Écocentre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {table}
                </tbody>
            </Table>
        </div>
    )
}

export default withRouter(UserList)