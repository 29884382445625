import React from 'react'
import Amherst from '../../assets/img/new/Amherst_logo.JPG'
import Arundel from '../../assets/img/new/arundel logo 2015 rgb150.jpg'
import Barkmere from '../../assets/img/new/Logo_Barkmere.png'
import Brebeuf from '../../assets/img/new/Logo_Brebeuf.jpg'
import Huberdeau from '../../assets/img/new/Huberdeau_Logo.jpg'
import IvrySurLcLac from '../../assets/img/new/Logo_Ivry.jpg'
import LaConception from '../../assets/img/new/Logo_La_Conception.jpg'
import LaMinerve from '../../assets/img/new/LOGO_La_Minerve.jpg'
import Labelle from '../../assets/img/new/LABELLE LOGO COEUR.jpg'
import LacSuperierur from '../../assets/img/new/Logo_Lac-Superieur.jpg'
import LacTremblantNord from '../../assets/img/new/Logo_LTN.jpg'
import Lantier from '../../assets/img/new/Logo_Lantier.jpg'
import Montcalm from '../../assets/img/new/Logo_Montcalm.jpg'
import MontTremblant from '../../assets/img/new/Logo_Mont-Tremblant_Couleur.jpg'
import SainteLucieDesLaurentides from '../../assets/img/new/Logo_SLDL.jpg'
import SaintFaustinLacCarre from '../../assets/img/new/Logo_SFLC.jpg'
import SaintsAgatheDesMonts from '../../assets/img/new/Logo_SAM.jpg'
import ValDavid from '../../assets/img/new/Logo_Val-David_Couleur.JPG'
import ValDesLacs from '../../assets/img/new/Logo_VDL.JPG'
import ValMorin from '../../assets/img/new/Logo_VM.jpg'

export default [
    {
        Id: "1",
        Name: "Amherst",
        Image: Amherst
    },
    {
        Id: "2",
        Name: "Arundel",
        Image: Arundel
    },
    {
        Id: "3",
        Name: "Barkmere",
        Image: Barkmere
    },
    {
        Id: "4",
        Name: "Brébeuf",
        Image: Brebeuf
    },
    {
        Id: "5",
        Name: "Huberdeau",
        Image: Huberdeau
    },
    {
        Id: "6",
        Name: "Ivry-sur-le-Lac",
        Image: IvrySurLcLac
    },
    {
        Id: "7",
        Name: "La Conception",
        Image: LaConception
    },
    {
        Id: "8",
        Name: "La Minerve",
        Image: LaMinerve
    },
    {
        Id: "9",
        Name: "Labelle",
        Image: Labelle
    },
    {
        Id: "10",
        Name: "Lac-Supérieur",
        Image: LacSuperierur
    },
    {
        Id: "11",
        Name: "Lac-Tremblant-Nord",
        Image: LacTremblantNord
    },
    {
        Id: "12",
        Name: "Lantier",
        Image: Lantier
    },
    {
        Id: "13",
        Name: "Mont Tremblant",
        Image: MontTremblant
    },
    {
        Id: "14",
        Name: "Montcalm",
        Image: Montcalm
    },
    {
        Id: "15",
        Name: "Sainte-Agathe-des-Monts",
        Image: SaintsAgatheDesMonts
    },
    {
        Id: "16",
        Name: "Sainte-Lucie-des-Laurentides",
        Image: SainteLucieDesLaurentides
    },
    {
        Id: "17",
        Name: "Saint-Faustin-lac-Carré",
        Image: SaintFaustinLacCarre
    },
    {
        Id: "18",
        Name: "Val-David",
        Image: ValDavid
    },
    {
        Id: "19",
        Name: "Val-des-Lacs",
        Image: ValDesLacs
    },
    {
        Id: "20",
        Name: "Val-Morin",
        Image: ValMorin
    }
]