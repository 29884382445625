import React, { useEffect } from 'react'
import './style.css'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'

function Login(props) {
    useEffect(() => {
        async function logInByToken() {
            let token = window.localStorage.getItem("token")
            let params = { token }
            if (token) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ ...params })
                }
                await fetch("/LoginByToken", requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.status === "Success") {
                                dispatch(Actions.setAuth(true))
                                dispatch(Actions.setUserId(result.data.id))
                                window.localStorage.setItem("userId", result.data.id)
                                window.localStorage.setItem("isAuth", true)
                                window.sessionStorage.setItem("isAdmin", result.data.isAdmin)
                                props.history.push("/home")
                            } else {
                                let span = document.getElementById("errorLogin")
                                span.classList.remove("resultSpan")
                            }
                        },
                        (error) => {
                            let span = document.getElementById("errorLogin")
                            span.classList.remove("resultSpan")
                        }
                    )
            }
        }

        let prevPage = window.sessionStorage.getItem("previousPage")
        let token = window.localStorage.getItem("token")
        if (prevPage === null && token !== null) {
            //logInByToken()
        }
    }, [])

    let email = useSelector(state => state.user.email)
    let pwd = useSelector(state => state.user.password)
    let dispatch = useDispatch()
    let isStayIn = false;

    const handleChange = (e) => {
        e.currentTarget.id === "email" ?
            dispatch(Actions.setEmail(e.currentTarget.value)) :
            dispatch(Actions.setPWD(e.currentTarget.value))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let supposedUser = {
            email: email,
            password: pwd
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...supposedUser })
        }

        await fetch("/Login", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setAuth(true))
                        dispatch(Actions.setUserId(result.data.id))
                        if (isStayIn) {
                            window.localStorage.setItem("token", result.data.token)
                            window.localStorage.setItem("isAuth", true)
                            window.localStorage.setItem("userId", result.data.id)
                            window.localStorage.setItem("ecoCentre", result.data.ecoCentre);

                        } else {
                            window.sessionStorage.setItem("token", result.data.token)
                            window.sessionStorage.setItem("isAuth", true)
                            window.sessionStorage.setItem("userId", result.data.id)
                            window.sessionStorage.setItem("ecoCentre", result.data.ecoCentre);
                        }
                        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
                        window.sessionStorage.setItem("isAdmin", result.data.isAdmin)
                        props.history.push("/home")
                    } else {
                        let span = document.getElementById("errorLogin")
                        span.classList.remove("resultSpan")
                    }
                },
                (error) => {
                    let span = document.getElementById("errorLogin")
                    span.classList.remove("resultSpan")
                }
            )
    }

    const handleRememberMeCheckChange = (e) => {
        isStayIn = !isStayIn
    }

    const emailInput = <input className="inputSize form-control" id="email"
        aria-describedby="emailHelp" placeholder="Entrez e-mail" value={email}
        onChange={handleChange} />

    const passwordInput = <input type="password" className="inputSize form-control" id="exampleInputPassword1"
        placeholder="Mot de passe" value={pwd} onChange={handleChange} />


    return (
        <div>
            <form className="loginForm" onSubmit={handleSubmit}>
                <div className="additionalRow">
                    <h1 className="header">S'identifier</h1>
                    <div className="text-align-bottom">
                        <a href="/registrate">N'avez pas de compte?</a>
                    </div>
                </div>
                <div className="form-group inputSizeDiv">
                    {emailInput}
                </div>
                <div className="form-group inputSizeDiv">
                    {passwordInput}
                </div>

                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="rememberCheck" onChange={handleRememberMeCheckChange} />
                    <div className="additionalRow">
                        <label className="form-check-label">Se souvenir de moi</label>
                        <a href="/forgot_password">Mot de passe oublié?</a>
                    </div>
                </div>
                <button type="submit" className="submitButton btn btn-primary">Soumettre</button>
                <div className="resultMainDiv">
                    <span className="alert alert-danger resultSpan" id="errorLogin" role="alert">
                        Erreur!
                    </span>
                </div>
            </form>
        </div>
    )
}

export default withRouter(Login)