import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import { withRouter } from 'react-router-dom'
import './navbar.css'
import { Navbar, Nav } from 'react-bootstrap'

function NavBar(props) {
    let userId = window.localStorage.getItem("userId") || window.sessionStorage.getItem("userId")
    let isAdmin = window.sessionStorage.getItem("isAdmin")
    let dispatch = useDispatch()

    useEffect(() => {
        let reportButton = document.getElementById("reportButton")
        let invoiceButton = document.getElementById("invoiceButton")
        let usersButton = document.getElementById("usersButton")
        if (isAdmin === "true") {
            reportButton.classList.remove("hide")
            invoiceButton.classList.remove("hide")
            usersButton.classList.remove("hide")
        }

        let currentNavTabId = "", previousNavTabIds = []
        if (props.history.location.pathname === "/home") {
            currentNavTabId = "homeButton"
            previousNavTabIds = ["reportButton", "invoiceButton", "usersButton"]
        }
        else if (props.history.location.pathname === "/report") {
            currentNavTabId = "reportButton"
            previousNavTabIds = ["homeButton", "invoiceButton", "usersButton"]
        }
        else if (props.history.location.pathname === "/invoice") {
            currentNavTabId = "invoiceButton"
            previousNavTabIds = ["reportButton", "homeButton", "usersButton"]
        }
        else if (props.history.location.pathname === "/users") {
            currentNavTabId = "usersButton"
            previousNavTabIds = ["reportButton", "homeButton", "invoiceButton"]
        }
        else {
            currentNavTabId = ""
            previousNavTabIds = []
        }
        let currentTab = document.getElementById(currentNavTabId)
        if (currentTab !== null) {
            currentTab.classList.add("current")
        }
        for (let tabId in previousNavTabIds) {
            let previousTab = document.getElementById(previousNavTabIds[tabId])
            if (previousTab !== null) {
                previousTab.classList.remove("current")
            }
        }
    }, [])

    const logout = async () => {
        let objectToSend = {
            userId
        }

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...objectToSend })
        }
        await fetch("/Logout", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        window.localStorage.clear()
                        window.sessionStorage.clear()
                        dispatch(Actions.setCurrentNavTab(""))
                        props.history.push("/")
                    }
                },
                (error) => {
                    console.log("Error of logout!")
                }
            )
    }

    const goToHome = () => {
        dispatch(Actions.setCurrentNavTab("homeButton"))
        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
        props.history.push("/home")
    }

    const goToReport = () => {
        dispatch(Actions.setCurrentNavTab("reportButton"))
        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
        props.history.push("/report")
    }

    const goToInvoice = () => {
        dispatch(Actions.setCurrentNavTab("invoiceButton"))
        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
        props.history.push("/invoice")
    }

    const goToUsersList =() => {
        dispatch(Actions.setCurrentNavTab("usersButton"))
        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
        props.history.push("/users")
    }

    const aClassName = isAdmin === "true" ? " " : "hide"

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            ref={ref}
            id="administrationButton"
            className={aClassName}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </a>
    ));

    return (
        <Navbar className="bachground" expand="lg">
            <Navbar.Brand id="mainButton" onClick={goToHome} className="pointer">Eco RIDR</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <a id="homeButton" onClick={goToHome} className="nav-link pointer current">Accueil</a>
                    <a id="reportButton" onClick={goToReport} className="nav-link pointer hide">Rapport</a>
                    <a id="invoiceButton" onClick={goToInvoice} className="nav-link pointer hide">Nouvelle Facture</a>
                    <a id="usersButton" onClick={goToUsersList} className="nav-link pointer hide">Utilisateur</a>
                    <a onClick={logout} className="nav-link pointer">Déconnexion</a>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default withRouter(NavBar)