import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import NavBar from '../common/navbar'
import Municipalities from '../Municipality/municipalities'
import './style.css'

function Invoice(props) {
    let isAuth = window.localStorage.getItem("isAuth") ? window.localStorage.getItem("isAuth") === "true" : window.sessionStorage.getItem("isAuth") === "true"
    let isAdmin = window.sessionStorage.getItem("isAdmin") === "true"

    useEffect(() => {
        if (isAuth) {
            if (!isAdmin) {
                props.history.push("/home")
            }
        } else {
            props.history.push("/")
        }
    }, [])

    let municipalities = Municipalities.slice()

    const handleClick = (e) => {
        let id = e.target.id.replace("image", "")
        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
        props.history.push("/municipalities/" + id, { id: id })
    }

    const pictureRows = municipalities.map((item, index) =>
        <div className="imageDiv" key={index}>
            <img className="image" id={item.Id} src={item.Image}></img>
        </div>

    )

    const firstRow = pictureRows.slice(0, 5)
    const secondRow = pictureRows.slice(5, 10)
    const thirdRow = pictureRows.slice(10, 15)
    const fourthRow = pictureRows.slice(15, 20)

    return (
        <div>
            <NavBar></NavBar>
            <div id="invoice-images" className="centerize">
                <div className="firstDivMargin" onClick={handleClick}>
                    <div className="rowDiv">
                        {firstRow}
                    </div>
                    <div className="rowDiv">
                        {secondRow}
                    </div>
                    <div className="rowDiv">
                        {thirdRow}
                    </div>
                    <div className="rowDiv">
                        {fourthRow}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default withRouter(Invoice)