import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import './style.css'
import Municipalities from './municipalities'
import NavBar from '../common/navbar'
import { useSelector } from 'react-redux'

function Municipality(props) {
    let userId = window.localStorage.getItem("userId") || window.sessionStorage.getItem("userId")
    let isAuth = window.localStorage.getItem("isAuth") ? window.localStorage.getItem("isAuth") === "true" : window.sessionStorage.getItem("isAuth") === "true"

    const addCitoyenUser = async () => {
        let path = props.history.location.pathname
        let indexOfCurrentMunicipality = path.slice(path.lastIndexOf("/") + 1)
        let municipalityName = Municipalities.slice()[indexOfCurrentMunicipality - 1].Name;
        let municipalityId = Number(Municipalities.slice()[indexOfCurrentMunicipality - 1].Id);
        let param = {
            userId: userId,
            municipalityName: municipalityName,
            municipalityId: municipalityId,
            type: "Particulier/Citoyen",
            dateOfVisit: new Date()
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...param })
        }
        let span;
        await fetch("/AddUser", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        span = document.getElementById("success")
                        span.classList.remove("resultSpan")
                        setTimeout(() => { props.history.push("/invoice") }, 1000)
                    } else {
                        span = document.getElementById("error")
                        span.classList.remove("resultSpan")
                    }
                },
                (error) => {
                    span = document.getElementById("error")
                    span.classList.remove("resultSpan")
                }
        )
    }

    const addCommerceUser = async () => {
        let path = props.history.location.pathname
        let indexOfCurrentMunicipality = path.slice(path.lastIndexOf("/") + 1)
        let municipalityName = Municipalities.slice()[indexOfCurrentMunicipality - 1].Name;
        let municipalityId = Number(Municipalities.slice()[indexOfCurrentMunicipality - 1].Id);
        let param = {
            userId: userId,
            municipalityName: municipalityName,
            municipalityId: municipalityId,
            type: "Institution/Commerce/Industrie",
            dateOfVisit: new Date()
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...param })
        }
        let span;
        await fetch("/AddUser", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        span = document.getElementById("success")
                        span.classList.remove("resultSpan")
                        setTimeout(() => { props.history.push("/invoice") }, 1000)
                    } else {
                        span = document.getElementById("error")
                        span.classList.remove("resultSpan")
                    }
                },
                (error) => {
                    span = document.getElementById("error")
                    span.classList.remove("resultSpan")
                }
            )
    }

    const backToHome = () => {
        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
        props.history.push("/invoice")
    }

    const municipalities = Municipalities.slice().map((municipality, index) =>
        <div key={index}>
            <div className="municipalityNameDiv" >
                <label className="municipalityNameLabel" id={municipality.Id}>{municipality.Name}</label>
            </div>
            <div className="municipalityImageDiv">
                <div className="center">
                    <button type="button" className="buttonSize btn btn-outline-success rowButton" onClick={addCitoyenUser}>Particulier/Citoyen</button>
                </div>
                <div className="municipalityImageMargin">
                    <img className="municipalityImage" src={municipality.Image}></img>
                </div>
                <div className="center">
                    <button type="button" className="buttonSize btn btn-outline-success rowButton" onClick={addCommerceUser}>Institution/Commerce/Industrie</button>
                </div>
            </div>
            <div className="munResultMainDiv">
                <span className="alert alert-success resultSpan adaptiveSpan" id="success" role="alert">
                    Succès!
                    </span>
                <span className="alert alert-danger resultSpan adaptiveSpan" id="error" role="alert">
                    Error!
                    </span>
            </div>
        </div>)

    const municipality = municipalities[+props.location.state.id - 1]

    if(!isAuth){
        return (<Redirect to="/" />)
    }

    return (
        <div>
            <NavBar></NavBar>
            <div className="mainDiv">
                {municipality}
                <div className="buttonsPrimaryDiv">
                    <button type="button" id="backToHomeButton" className="buttonSize btn btn-outline-info" onClick={backToHome}>Retour au menu principale</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Municipality)