import React, { useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import NavBar from '../common/navbar'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import EcoCenters from '../EcoCenters'


function EditUser(props) {
    let isAuth = window.localStorage.getItem("isAuth") ? window.localStorage.getItem("isAuth") === "true" : window.sessionStorage.getItem("isAuth") === "true"
    let isAdmin = window.sessionStorage.getItem("isAdmin");
    let path = props.history.location.pathname;
    let pwd = "", confirmPwd = "", newUser = {
        isAdmin: "false"
    }
    let dispatch = useDispatch();

    if (!isAdmin) {
        return (<Redirect to="/home" />)
    }

    if (!isAuth) {
        return (<Redirect to="/" />)
    }

    const handleChange = (e) => {
        e.currentTarget.id === "username" ?
            newUser.username = e.currentTarget.value :
            (e.currentTarget.id === "email" ?
                newUser.email = e.currentTarget.value :
                (e.currentTarget.id === "password" ?
                    newUser.password = e.currentTarget.value :
                    (e.currentTarget.id === "confirmPassword" ?
                        newUser.confirmPassword = e.currentTarget.value :
                        (e.currentTarget.id === "adminButton" ?
                            newUser.isAdmin = "true" :
                            newUser.isAdmin = "false"
                        )
                    )
                )
            )
    }

    const handleEcoCenterChange = (e) => {
        let selected = document.getElementById("dropdown-basic-eco-center");
        selected.innerText = e.target.innerText;
        newUser.EcoCenterSelected = e.target.innerText;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let successMessage = document.getElementById("successUserCreate");
        let errorMessage = document.getElementById("errorUserCreate");
        if (newUser.EcoCenterSelected === "") {
            errorMessage.classList.remove("resultSpan");
            successMessage.classList.add("resultSpan");
            return;
        }
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...newUser })
        }

        if (newUser.password !== newUser.confirmPassword) {
            errorMessage.classList.remove("resultSpan");
            successMessage.classList.add("resultSpan");
            return;
        }

        await fetch("/CreateUser", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        successMessage.classList.remove("resultSpan")
                        errorMessage.classList.add("resultSpan")
                        setTimeout(() => { props.history.push("/users") }, 1000)
                    } else {
                        errorMessage.classList.remove("resultSpan")
                        successMessage.classList.add("resultSpan")
                    }
                },
                (error) => {
                    errorMessage.classList.remove("resultSpan")
                    successMessage.classList.add("resultSpan")
                }
            )
    }

    const usernameInput = <input className="inputSize form-control" id="username"
        aria-describedby="emailHelp" placeholder="Entrez utilisateur"
        onChange={handleChange} />

    const emailInput = <input type="email" className="inputSize form-control" id="email"
        aria-describedby="emailHelp" placeholder="Entrez e-mail" 
        onChange={handleChange} />

    const passwordInput = <input type="password" className="inputSize form-control" id="password"
        placeholder="Mot de passe" onChange={handleChange} />

    const passwordConfirmInput = <input type="password" className="inputSize form-control" id="confirmPassword"
        placeholder="Confirmer le mot de passe" onChange={handleChange} />

    let adminCheck = <Form.Check
        type="radio"
        id="adminButton"
        label="Administrateur"
        name="userType"
        onChange={handleChange}
    />

    let userCheck = <Form.Check
        type="radio"
        label="Utilisateur"
        id="userButton"
        name="userType"
        onChange={handleChange}
        defaultChecked="true"
    />

    const goBack = () => {
        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
        props.history.push("/users")
    }

    let ecoCentersForDropdown = EcoCenters.slice()
    const ecoCenters = ecoCentersForDropdown.map((ecoCenter, index) =>
        <Dropdown.Item key={index} onClick={handleEcoCenterChange}>{ecoCenter.Name}</Dropdown.Item>
    )

    return (
        <div>
            <NavBar></NavBar>
            <button className="btn btn-primary margin-top-left" onClick={goBack}>Arrière</button>
            <form className="registrationForm editForm" onSubmit={handleSubmit}>
                <h1 className="header">Nouveau l'utilisateur</h1>
                <div className="form-group inputSizeDiv">
                    {emailInput}
                </div>
                <div className="form-group inputSizeDiv">
                    {usernameInput}
                </div>
                <div className="form-group inputSizeDiv">
                    {passwordInput}
                </div>
                <div className="form-group inputSizeDiv">
                    {passwordConfirmInput}
                </div>
                <div className="mb-3">
                    {adminCheck}

                    {userCheck}
                </div>
                <div className="form-group searchDiv" id="municipalityDiv">
                    <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic-eco-center">
                    Écocentre
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    {ecoCenters}
                    </Dropdown.Menu>
                    </Dropdown>
                </div>
                <button type="submit" className="submitButton btn btn-primary">Soumettre</button>
                <div className="munResultMainDiv">
                    <span className="alert alert-success resultSpan adaptiveSpan" id="successUserCreate" role="alert">
                        Succès!
                    </span>
                    <span className="alert alert-danger resultSpan adaptiveSpan" id="errorUserCreate" role="alert">
                        Error!
                    </span>
                </div>
            </form>
        </div>
    )
}

export default withRouter(EditUser)