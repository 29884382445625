import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import './forgotPassword.css'

function ForgotPassword(props) {
    let recoveryEmail = ""

    const handleChange = (e) => {
        recoveryEmail = e.currentTarget.value
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ recoveryEmail })
        }

        await fetch("/ForgotPassword", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        let notification = document.getElementById("forgotPasswordNotification")
                        notification.classList.remove("resultSpan")
                    } else {
                        let span = document.getElementById("errorSendRecoveryLink")
                        span.classList.remove("resultSpan")
                    }
                },
                (error) => {
                    let span = document.getElementById("errorSendRecoveryLink")
                    span.classList.remove("resultSpan")
                }
            )
    }

    return (
        <form className="loginForm forgotPasswordForm" onSubmit={handleSubmit}>
            <h1 className="header">Entrez votre e-mail</h1>
            <input type="email" className="inputSize form-control" id="recoveryEmail"
                aria-describedby="emailHelp" placeholder="Entrez e-mail" defaultValue={recoveryEmail}
                onChange={handleChange} />
            <div className="additionalRow">
                <label>Nous allons envoyer un lien de récupération à elle.</label>
                <a href="/">Connecter</a>
            </div>
            <button type="submit" className="submitButton btn btn-primary">Envoyer un email</button>
            <div className="resultMainDiv">
                <span className="alert alert-danger resultSpan" id="errorSendRecoveryLink" role="alert">
                    Erreur!
                </span>
                <span className="alert alert-success resultSpan" id="forgotPasswordNotification" role="alert">
                    Le lien de récupération a été envoyé à votre e-mail.
                </span>
            </div>
        </form>
    )
}

export default withRouter(ForgotPassword)