import React, { useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import './style.css'
import NavBar from '../common/navbar'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-bootstrap/Dropdown'
import Municipalities from '../Municipality/municipalities'
import Table from 'react-bootstrap/Table'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import ReactToExcel from 'react-html-table-to-excel'
import EcoCenters from '../EcoCenters'

function Report(props) {
    let startDate = useSelector(state => state.report.startDate)
    let endDate = useSelector(state => state.report.endDate)
    let isNeedFiltersClearing = !(window.sessionStorage.getItem("previousPage") === "report")
    let selectedMunicipality = useSelector(state => state.report.selectedMunicipality)
    let isTypeFilterValueChange = useSelector(state => state.isTypeChange)
    let selectedUserType = useSelector(state => state.report.selectedUserType)

    let dispatch = useDispatch()

    useEffect(() => {
        if (isNeedFiltersClearing) {
            selectedMunicipality = ""
            selectedUserType = ""
        }
    }, [])

    //let tableData = useSelector(state => state.tableData)
    let rowsWithData = useSelector(state => state.tableData)
    let ecoCenterCounts = {
        municipalityName: "",
        counts: [],
        residentialTotalCount: 0,
        commerceTotalCount: 0
    }
    let lastRowCounts = useSelector(state => state.reportTableTotalCounts)
    let residentialTableDataCounts = []
    let commercialTableDataCounts = []

    const _userTypes = [
        "Particulier/Citoyen",
        "Institution/Commerce/Industrie"
    ]

    const handleStartDateChange = date => {
        dispatch(Actions.setStartDate(date))
        startDate = date
    };

    const handleEndDateChange = date => {
        dispatch(Actions.setEndDate(date))
        endDate = date
    };

    const handleMunicipalityChange = (e) => {
        dispatch(Actions.setSelectedMunicipality(e.target.innerText))
        let selected = document.getElementById("dropdown-basic")
        selected.innerText = e.target.innerText
        selectedMunicipality = e.target.innerText
    }

    const handleUserTypeChange = (e) => {
        //dispatch(Actions.setSelectedUserType(e.target.innerText))
        let selected = document.getElementById("dropdown-basic_2")
        selected.innerText = e.target.innerText
        selectedUserType = e.target.innerText
    }

    const handleSubmit = (e) => {
        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
        let _startDate = (startDate != undefined) ? startDate : new Date(),
            _endDate = (endDate != undefined) ? endDate : new Date(),
            userType = selectedUserType,
            municipality = selectedMunicipality === "Tous" ? "" : selectedMunicipality
        let filter = {
            from: _startDate,
            to: _endDate,
            userType,
            municipalityName: municipality
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...filter })
        }
        let span;
        fetch("/GetReport", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    let tableData = result.data
                    let data = []
                    let totalCounts = []
                    tableData.map((item, index) => {
                        ecoCenterCounts.municipalityName = item.municipalityName
                        Object.values(item.ecoCenterUsersCount).map(function (element, index) {
                            element.map((e, i) => {
                                ecoCenterCounts.counts.push(e);
                                if (!selectedUserType) {
                                    (i % 2 === 0) ? ecoCenterCounts.residentialTotalCount += e : ecoCenterCounts.commerceTotalCount += e
                                } else {
                                    selectedUserType === userTypes[0].props.children ? ecoCenterCounts.residentialTotalCount += e : ecoCenterCounts.commerceTotalCount += e
                                }
                            })
                        })
                        data.push(ecoCenterCounts)
                        ecoCenterCounts = {
                            municipalityName: "",
                            counts: [],
                            residentialTotalCount: 0,
                            commerceTotalCount: 0
                        }
                    })
                    let sum = []
                    data.forEach(element => {
                        element.counts.map((e, i) => {
                            totalCounts[i] ? totalCounts[i] += e : totalCounts[i] = e
                        })
                        if (!selectedUserType) {
                            sum[0] ? sum[0] += element.residentialTotalCount : sum[0] = element.residentialTotalCount
                            sum[1] ? sum[1] += element.commerceTotalCount : sum[1] = element.commerceTotalCount
                        } else if (selectedUserType === userTypes[0].props.children) {
                            sum[0] ? sum[0] += element.residentialTotalCount : sum[0] = element.residentialTotalCount
                        } else {
                            sum[0] ? sum[0] += element.commerceTotalCount : sum[0] = element.commerceTotalCount
                        }
                    })
                    //if (selectedUserType) {
                    //    if (selectedUserType === userTypes[0].props.children) {
                    //        totalCounts = totalCounts.filter(
                    //            (item, index) => {
                    //                if (index % 2 === 0) {
                    //                    return true
                    //                } else {
                    //                    return false
                    //                }
                    //            })
                    //        data.map((e, i) => {
                    //            e.counts = e.counts.filter(
                    //                (item, index) => {
                    //                    if (index % 2 === 0) {
                    //                        return true
                    //                    } else {
                    //                        return false
                    //                    }
                    //                })
                    //        })
                    //    } else {
                    //        totalCounts = totalCounts.filter(
                    //            (item, index) => {
                    //                if (index % 2 !== 0) {
                    //                    return true
                    //                } else {
                    //                    return false
                    //                }
                    //            })
                    //        data = data.map((e, i) => {
                    //           e.counts = e.counts.filter(
                    //                (item, index) => {
                    //                    if (index % 2 !== 0) {
                    //                        return true
                    //                    } else {
                    //                        return false
                    //                    }
                    //                })
                    //        })
                    //    }
                    //}
                    totalCounts.push(...sum)
                    dispatch(Actions.setSelectedUserType(selectedUserType))
                    dispatch(Actions.setTableData(data))
                    dispatch(Actions.setReportTableTotalCounts(totalCounts))
                    span = document.getElementById("successReport")
                    span.classList.remove("resultSpan")
                    let tableBody = document.getElementById("report-table-body")
                    tableBody.classList.remove("hide")
                    if (selectedUserType) {
                        dispatch(Actions.setIsTypeChange(true))
                    }
                },
                (error) => {
                    span = document.getElementById("errorReport")
                    span.classList.remove("resultSpan")
                }
            )
        e.preventDefault();
    }

    let municipalitiesForDropdown = Municipalities.slice()
    municipalitiesForDropdown.unshift({ Id: "0", Name: "Tous", Image: "" })

    const municipalities = municipalitiesForDropdown.map((municipality, index) =>
        <Dropdown.Item key={index} onClick={handleMunicipalityChange}>{municipality.Name}</Dropdown.Item>
    )

    const userTypes = _userTypes.map((userType, index) =>
        <Dropdown.Item key={index} onClick={handleUserTypeChange}>{userType}</Dropdown.Item>
    )

    let ecoCenters = !isTypeFilterValueChange ? EcoCenters.map((item, index) => (<><th>{item.Name}</th><th>{item.Name}</th></>)) : EcoCenters.map((item, index) => (<><th>{item.Name}</th></>))

    let usersHeaders = !isTypeFilterValueChange ? EcoCenters.map((element, index) => (<><th>Citoyen</th><th>Commerce</th></>)) : (selectedUserType === userTypes[0].props.children ? EcoCenters.map((element, index) => (<><th>Citoyen</th></>)) : EcoCenters.map((element, index) => (<><th>Commerce</th></>)))

    let rows = !isTypeFilterValueChange ? rowsWithData.map((item, index) => (
        <tr key={item.municipalityName}>
            <td>{item.municipalityName}</td>
            {item.counts.map((element, i) => (
                <td>{element}</td>
            ))}
            <td></td>
            <td></td>
            <td>{item.residentialTotalCount}</td>
            <td>{item.commerceTotalCount}</td>
        </tr>
    )) : (selectedUserType === userTypes[0].props.children ?
        rowsWithData.map((item, index) => (
            <tr key={item.municipalityName}>
                <td>{item.municipalityName}</td>
                {item.counts.map((element, i) => (
                    <td>{element}</td>
                ))}
                <td></td>
                <td></td>
                <td>{item.residentialTotalCount}</td>
            </tr>
        )) :
        rowsWithData.map((item, index) => (
            <tr key={item.municipalityName}>
                <td>{item.municipalityName}</td>
                {item.counts.map((element, i) => (
                    <td>{element}</td>
                ))}
                <td></td>
                <td></td>
                <td>{item.commerceTotalCount}</td>
            </tr>
        )))

    let totalColumns = !isTypeFilterValueChange ? (<><th>Totale</th> <th>Totale</th></>) : (<th>Totale</th>)

    let typesColumn = !isTypeFilterValueChange ? (<><th>Citoyen</th> <th>Commerce</th></>) : (selectedUserType === userTypes[0].props.children ? (<th>Citoyen</th>) : (<th>Commerce</th>))

    let emptyRow = !isTypeFilterValueChange ? EcoCenters.map((item, index) => (<><td></td><td></td></>)) : EcoCenters.map((item, index) => (<><td></td></>))

    let emptyRowsEnd = !isTypeFilterValueChange ? (
        <>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </>
    ) : (
            <>
                <td></td>
                <td></td>
                <td></td>
            </>
        )

    let emptyRows = (
        <>
            <tr>
                <td></td>
                {emptyRow}
                {emptyRowsEnd}
            </tr>
            <tr>
                <td></td>
                {emptyRow}
                {emptyRowsEnd}
            </tr>
            <tr>
                <td></td>
                {emptyRow}
                {emptyRowsEnd}
            </tr>
        </>
    )

    let lastRow = isTypeFilterValueChange ? lastRowCounts.slice(0, lastRowCounts.length - 1).map((item, index) => (
        <td>{item}</td>
    )) : lastRowCounts.slice(0, lastRowCounts.length - 2).map((item, index) => (
        <td>{item}</td>
    ))

    let lastRowTotals = !isTypeFilterValueChange ?
        (
            <>
                <td>{lastRowCounts[lastRowCounts.length - 2]}</td>
                <td>{lastRowCounts[lastRowCounts.length - 1]}</td>
            </>
        ) :
        (
            <td>{lastRowCounts[lastRowCounts.length - 1]}</td>
        )



    if ((window.sessionStorage.getItem("isAuth") ? window.sessionStorage.getItem("isAuth") : window.localStorage.getItem("isAuth")) === "true") {
        return (
            <div>
                <NavBar></NavBar>
                <div className="reportControllBar">
                    <div className="reportControllBarDiv">
                        <form className="searchForm" onSubmit={handleSubmit}>
                            <div className="searchFieldsDiv">
                                <div className="form-group searchDiv" id="periodDiv">
                                    <label>Date:</label>
                                    <div className="datePickerMainDiv">
                                        <div className="datePickerDiv">
                                            <label className="datePickerLabel">From</label>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={handleStartDateChange}
                                            />
                                        </div>
                                        <div className="datePickerDiv">
                                            <label className="datePickerLabel">To</label>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={handleEndDateChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group searchDiv" id="municipalityDiv">
                                    <label>Municipalité:</label>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Choisir
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {municipalities}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="form-group searchDiv" id="userTypeDiv">
                                    <label>Type:</label>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic_2">
                                            Choisir
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {userTypes}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className="buttons-div">
                                <div className="searchButton">
                                    <button type="submit" className="submitButton btn btn-primary" id="searchButton">Rechercher</button>
                                </div>
                                <div className="exportButton">
                                    <div className="reportExportButton">
                                        <ReactToExcel
                                            className="btn btn-primary"
                                            table="resultTable"
                                            filename={"eco_ridr_new_report_" + new Date().toLocaleString()}
                                            sheet="Sheet 1"
                                            buttonText="Exporter vers excel"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="resultMainDiv">
                        <div className="alert alert-success resultSpan" id="successReport" role="alert">
                            Succès!
                        </div>
                        <div className="alert alert-danger resultSpan" id="errorReport" role="alert">
                            Erreur!
                        </div>
                    </div>
                </div>
                <div>
                    <Table striped bordered hover id="resultTable">
                        <thead>
                            <tr>
                                <th></th>
                                {ecoCenters}
                                <th></th>
                                <th></th>
                                {totalColumns}
                            </tr>
                            <tr>
                                <th>Nom</th>
                                {usersHeaders}
                                <th></th>
                                <th></th>
                                {typesColumn}
                            </tr>
                        </thead>
                        <tbody id="report-table-body" className="hide">
                            {rows}
                            {emptyRows}
                            <tr>
                                <td>Totale:</td>
                                {lastRow}
                                <td></td>
                                <td></td>
                                {lastRowTotals}
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div >
        )
    } else {
        return (
            <Redirect to="/" />
        )
    }
}

export default withRouter(Report)