import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import './forgotPassword.css'

function NewPassword(props) {
    let newPassword = "", confirmNewPassword = "", user = {}

    useEffect(() => {
        let userId = atob(props.match.params.token)

        async function getUserById() {
            await fetch("/GetUserById?userId=" + userId)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.status === "Success") {
                            user = result.data
                        } else {
                            let span = document.getElementById("errorRecoverPassword")
                            span.classList.remove("resultSpan")
                        }
                    },
                    (error) => {
                        let span = document.getElementById("errorRecoverPassword")
                        span.classList.remove("resultSpan")
                    }
                )
        }

        getUserById()
    }, [])

    const handleChange = (e) => {
        if (e.currentTarget.id === "newPwd") {
            newPassword = e.currentTarget.value
        } else {
            confirmNewPassword = e.currentTarget.value
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let span = document.getElementById("errorRecoverPassword")
        let userId = user.id
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ newPassword, userId })
        }

        if (!newPassword || !confirmNewPassword || newPassword !== confirmNewPassword) {
            span.classList.remove("resultSpan")
            return
        }

        await fetch("/RecoverPassword", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        props.history.push("/")
                    } else {
                        span.classList.remove("resultSpan")
                    }
                },
                (error) => {
                    span.classList.remove("resultSpan")
                }
            )
    }

    return (
        <form className="loginForm forgotPasswordForm" onSubmit={handleSubmit}>
            <h1 className="header">Nouveau mot de passe</h1>
            <div className="form-group inputSizeDiv">
                <input type="password" className="inputSize form-control" id="newPwd"
                    placeholder="Entrez le mot de passe" defaultValue={newPassword}
                    onChange={handleChange} />
            </div>
            <div className="form-group inputSizeDiv">
                <input type="password" className="inputSize form-control" id="confirmNewPwd"
                    placeholder="Confirmer le mot de passe" defaultValue={confirmNewPassword}
                    onChange={handleChange} />
            </div>
            <button type="submit" className="submitButton btn btn-primary">Confirmer</button>
            <div className="resultMainDiv">
                <span className="alert alert-danger resultSpan" id="errorRecoverPassword" role="alert">
                    Erreur!
                </span>
            </div>
        </form>
    )
}

export default withRouter(NewPassword)