import React, { Component } from 'react'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import Login from './components/Login'
import Home from './components/Home'
import Municipality from './components/Municipality'
import Header from './components/common/header'
import Report from './components/Report'
import Registration from './components/Registration'
import Invoice from './components/Invoice'
import UsersList from './components/UsersList'
import EditUser from './components/EditUser'
import ForgotPassword from './components/PasswordRecovery/forgotPassword'
import NewPassword from './components/PasswordRecovery/newPassword'
import CreateUser from './components/CreateUser'

export default class App extends Component {
  componentWillMount() {
    document.body.style.backgroundColor = "white"
  }

  render() {
    return (
      <div>
        <Header></Header>
        <Router>
          <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/home' component={Home} />
            <Route exact path='/municipalities/:id' component={Municipality} />
            <Route exact path='/report' component={Report} />
            <Route exact path='/invoice' component={Invoice} />
            <Route exact path='/registrate' component={Registration} />
            <Route exact path='/users' component={UsersList} />
            <Route exact path='/users/edit/:id' component={EditUser} />
            <Route exact path='/forgot_password' component={ForgotPassword} />
            <Route exact path='/new_password/:token' component={NewPassword} />
            <Route exact path='/users/new' component={CreateUser} />
          </Switch>
        </Router>
      </div>
    );
  }
}
