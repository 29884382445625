import React from 'react'

export default [
    {
        Id: "1",
        Name: "Amherst"
    },
    {
        Id: "2",
        Name: "Huberdeau"
    },
    {
        Id: "3",
        Name: "La Minerve"
    },
    {
        Id: "4",
        Name: "Lac-Supérieur"
    },
    {
        Id: "5",
        Name: "Lantier"
    },
    {
        Id: "6",
        Name: "Mont Tremblant"
    },
    {
        Id: "7",
        Name: "Sainte-Agathe-des-Monts"
    },
    {
        Id: "8",
        Name: "Sainte-Lucie-des-Laurentides"
    },
    {
        Id: "9",
        Name: "Val-des-Lacs"
    },
    {
        Id: "10",
        Name: "Administrator"
    }
]