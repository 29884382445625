import React from 'react'
import './style.css'
import { withRouter } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'
import EcoCenters from '../EcoCenters'
import Dropdown from 'react-bootstrap/Dropdown'

function Registration(props) {
    let email = useSelector(state => state.user.email)
    let pwd = useSelector(state => state.user.password)
    let confirmPwd = useSelector(state => state.user.confirmPassword)
    let dispatch = useDispatch()
    let isStayIn = false
    let selectedEcoCenter = window.sessionStorage.getItem("ecoCenter")
    const handleChange = (e) => {
        e.currentTarget.id === "email" ?
            dispatch(Actions.setEmail(e.currentTarget.value)) :
            (e.currentTarget.id === "password" ?
                dispatch(Actions.setPWD(e.currentTarget.value)) :
                (e.currentTarget.id === "confirmPassword" ?
                    dispatch(Actions.setConfirmPassword(e.currentTarget.value)) :
                    dispatch(Actions.setMunicipality(e.currentTarget.value))
                )
            )
    }

    const handleRememberMeCheckChange = (e) => {
        if (e.currentTarget.value === "on") {
            isStayIn = true
        } else {
            isStayIn = false
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!pwd || !confirmPwd || !email || pwd !== confirmPwd) {
            let span = document.getElementById("errorLogin")
            span.classList.remove("resultSpan")
            return
        } else {
            let span = document.getElementById("errorLogin")
            span.classList.add("resultSpan")
        }

        let newUser = {
            email: email,
            password: pwd,
            municipality: selectedEcoCenter,
            EcoCenterSelected: selectedEcoCenter
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...newUser })
        }
        await fetch("/Registration", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "Success") {
                        dispatch(Actions.setAuth(true))
                        dispatch(Actions.setUserId(result.data.id))
                        if (isStayIn) {
                            window.localStorage.setItem("token", result.data.token)
                            window.localStorage.setItem("isAuth", true)
                            window.localStorage.setItem("userId", result.data.id)
                        } else {
                            window.sessionStorage.setItem("token", result.data.token)
                            window.sessionStorage.setItem("isAuth", true)
                            window.sessionStorage.setItem("userId", result.data.id)
                        }
                        window.sessionStorage.setItem("previousPage", props.history.location.pathname)
                        window.sessionStorage.setItem("isAdmin", result.data.isAdmin)
                        props.history.push("/home")
                    }
                    else {
                        let span = document.getElementById("errorLogin")
                        span.classList.remove("resultSpan")
                    }
                },
                (error) => {
                    let span = document.getElementById("errorLogin")
                    span.classList.remove("resultSpan")
                }
            )
    }

    const handleEcoCenterChange = (e) => {
        window.sessionStorage.setItem("ecoCenter", e.target.innerText)
        let selected = document.getElementById("registration-dropdown-basic-eco-center")
        selected.innerText = e.target.innerText
        selectedEcoCenter = e.target.innerText
    }

    const emailInput = <input className="inputSize form-control" id="email"
        aria-describedby="emailHelp" placeholder="Entrez e-mail" value={email}
        onChange={handleChange} />

    const passwordInput = <input type="password" className="inputSize form-control" id="password"
        placeholder="Mot de passe" value={pwd} onChange={handleChange} />

    const passwordConfirmInput = <input type="password" className="inputSize form-control" id="confirmPassword"
        placeholder="Confirmer le mot de passe" value={confirmPwd} onChange={handleChange} />

        let ecoCentersForDropdown = EcoCenters.slice()
        const ecoCenters = ecoCentersForDropdown.map((ecoCenter, index) =>
            <Dropdown.Item key={index} onClick={handleEcoCenterChange}>{ecoCenter.Name}</Dropdown.Item>
        )

    return (
        <div>
            <form className="registrationForm" onSubmit={handleSubmit}>
                <h1 className="header">Enregistrement</h1>
                <div className="form-group inputSizeDiv">
                    {emailInput}
                </div>
                <div className="form-group inputSizeDiv">
                    {passwordInput}
                </div>
                <div className="form-group inputSizeDiv">
                    {passwordConfirmInput}
                </div>
                <div className="form-group searchDiv" id="municipalityDiv">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="registration-dropdown-basic-eco-center">
                            Écocentre
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {ecoCenters}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <button type="submit" className="submitButton btn btn-primary">Soumettre</button>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="rememberCheck" onChange={handleRememberMeCheckChange} />
                    <div className="additionalRow">
                        <label className="form-check-label">Se souvenir de moi</label>
                        <a href="/">Ont déjà un compte?</a>
                    </div>
                </div>
                <div className="resultMainDiv">
                    <span className="alert alert-danger resultSpan" id="errorLogin" role="alert">
                        Erreur!
                    </span>
                </div>
            </form>
        </div>
    )
}

export default withRouter(Registration)