import { Actions } from '../constants/actionsConstants'

const initialState = {
    user: {
        userId: null,
        email: "",
        password: "",
        confirmPassword: "",
        isAuth: false,
        municipality: ""
    },
    residential: {
        today: 0,
        month: 0,
        year: 0
    },
    commercial: {
        today: 0,
        month: 0,
        year: 0
    },
    report: {
        startDate: new Date(),
        endDate: new Date(),
        selectedMunicipality: "",
        selectedUserType: ""
    },
    usersForMunicipalities: [],
    currentNavbarTabId: "homeButton",
    tableData: [],
    showMunicipalities: false,
    usersList: [],
    reportTableTotalCounts: [],
    isTypeChange: false
  }

export const rootReducer = (state = initialState, action) =>{
    switch(action.type){
        case Actions.SET_USER_ID: {
            return {...state, user: {...state.user, userId: action.payload }}
        }
        case Actions.SET_AUTH: {
            return {...state, user: {...state.user, isAuth: action.payload }}
        }
        case Actions.SET_PWD: {
            return {...state, user: {...state.user, password: action.payload }}
        }
        case Actions.SET_EMAIL: {
            return {...state, user: {...state.user, email: action.payload }}
        }
        case Actions.SET_RESIDENTIAL_FOR_TODAY: {
            return {...state, residential: {...state.residential, today: action.payload }}
        }
        case Actions.SET_RESIDENTIAL_FOR_MONTH: {
            return {...state, residential: {...state.residential, month: action.payload }}
        }
        case Actions.SET_RESIDENTIAL_FOR_YEAR: {
            return {...state, residential: {...state.residential, year: action.payload }}
        }
        case Actions.SET_COMMERCIAL_FOR_TODAY: {
            return {...state, commercial: {...state.commercial, today: action.payload }}
        }
        case Actions.SET_COMMERCIAL_FOR_MONTH: {
            return {...state, commercial: {...state.commercial, month: action.payload }}
        }
        case Actions.SET_COMMERCIAL_FOR_YEAR: {
            return {...state, commercial: {...state.commercial, year: action.payload }}
        }
        case Actions.SET_USERS_FOR_MUNICIPALITIES: {
            return {...state, usersForMunicipalities: action.payload.slice() }
        }
        case Actions.SET_CURRENT_NAV_TAB: {
            return {...state, currentNavbarTabId: action.payload}
        }
        case Actions.SET_MUNICIPALITY: {
            return {...state, user: {...state.user, municipality: action.payload }}
        }
        case Actions.SET_START_DATE: {
            return {...state, report: {...state.report, startDate: action.payload }}
        }
        case Actions.SET_END_DATE: {
            return {...state, report: {...state.report, endDate: action.payload }}
        }
        case Actions.SET_SELECTED_MUNICIPALITY: {
            return {...state, report: {...state.report, selectedMunicipality: action.payload }}
        }
        case Actions.SET_SELECTED_USER_TYPE: {
            return {...state, report: {...state.report, selectedUserType: action.payload }}
        }
        case Actions.SET_TABLE_DATA: {
            return {...state, tableData: action.payload}
        }
        case Actions.SET_CONFIRM_PASSWORD: {
            return {...state, user: {...state.user, confirmPassword: action.payload }}
        }
        case Actions.SET_SHOW_MUNICIPALITIES: {
            return {...state, showMunicipalities: action.payload}
        }
        case Actions.SET_USERS_LIST: {
            return {...state, usersList: action.payload}
        }
        case Actions.SET_REPORT_TABLE_TOTAL_COUNTS: {
            return {...state, reportTableTotalCounts: action.payload}
        }
        case Actions.SET_IS_TYPE_CHANGE: {
            return { ...state, isTypeChange: action.payload }
        }
        default: {
            return state;
        }
    }
  }