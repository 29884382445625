import React, { useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import './style.css'
import NavBar from '../common/navbar'
import Table from 'react-bootstrap/Table'
import Municipalities from '../Municipality/municipalities'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from '../../store/actions'


function Home(props) {
    let dispatch = useDispatch()
    let isAuth = window.localStorage.getItem("isAuth") ? window.localStorage.getItem("isAuth") === "true" : window.sessionStorage.getItem("isAuth") === "true"
    let isAdmin = window.sessionStorage.getItem("isAdmin")
    let showMunicipalities = useSelector(state => state.showMunicipalities);
    let municipalities = Municipalities.slice();
    const ecoCentreSelected = window.sessionStorage.getItem("ecoCentre");
    useEffect(() => {
        async function getResidentialCount(param) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ param })
            }
            await fetch("/GetResidentialCount", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        switch (param) {
                            case "Today": {
                                dispatch(Actions.setResidentialForToday(result.data.count))
                                break
                            }
                            case "Month": {
                                dispatch(Actions.setResidentialForMonth(result.data.count))
                                break
                            }
                            case "Year": {
                                dispatch(Actions.setResidentialForYear(result.data.count))
                                break
                            }
                        }
                    },
                    (error) => {
                        switch (param) {
                            case "Today": {
                                dispatch(Actions.setResidentialForToday(0))
                                break
                            }
                            case "Month": {
                                dispatch(Actions.setResidentialForMonth(0))
                                break
                            }
                            case "Year": {
                                dispatch(Actions.setResidentialForYear(0))
                                break
                            }
                        }
                    }
                )
        }

        async function getCommercialCount(param) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ param })
            }
            await fetch("/GetCommercialCount", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        switch (param) {
                            case "Today": {
                                dispatch(Actions.setCommercialForToday(result.data.count))
                                break
                            }
                            case "Month": {
                                dispatch(Actions.setCommercialForMonth(result.data.count))
                                break
                            }
                            case "Year": {
                                dispatch(Actions.setCommercialForYear(result.data.count))
                                break
                            }
                        }
                    },
                    (error) => {
                        switch (param) {
                            case "Today": {
                                dispatch(Actions.setCommercialForToday(0))
                                break
                            }
                            case "Month": {
                                dispatch(Actions.setCommercialForMonth(0))
                                break
                            }
                            case "Year": {
                                dispatch(Actions.setCommercialForYear(0))
                                break
                            }
                        }
                    }
                )
        }

        async function getMunicipalitiesUsersCount() {
            await fetch("/GetMunicipalitiesUsersCount")
                .then(res => res.json())
                .then(
                    (result) => {
                        dispatch(Actions.setUsersForMunicipalities(result.data))
                    },
                    (error) => {
                        dispatch(Actions.setUsersForMunicipalities([]))
                    }
                )
        }

        if (isAdmin.toLowerCase() === "true") {
            let tables = document.getElementById("tablesDiv")
            if (tables) {
                tables.classList.remove("hidenDiv")
            }
            showMunicipalities = false
            dispatch(Actions.setShowMunicipalities(false))
        } else {
            showMunicipalities = true
            dispatch(Actions.setShowMunicipalities(true))
        }

        if (!showMunicipalities) {
            let images = document.getElementById("imagesDiv")
            if (images) {
                images.classList.add("hidenDiv")
            }
        }

        getResidentialCount("Today")
        getResidentialCount("Month")
        getResidentialCount("Year")
        getCommercialCount("Today")
        getCommercialCount("Month")
        getCommercialCount("Year")
        getMunicipalitiesUsersCount()
    }, [])

    
    let residentialCountForToday = useSelector(state => state.residential.today)
    let residentialCountForMonth = useSelector(state => state.residential.month)
    let residentialCountForYear = useSelector(state => state.residential.year)
    let commercialCountForToday = useSelector(state => state.commercial.today)
    let commercialCountForMonth = useSelector(state => state.commercial.month)
    let commercialCountForYear = useSelector(state => state.commercial.year)
    let usersForMunicipalities = useSelector(state => state.usersForMunicipalities)

    if (!isAuth) {
        return (
            <Redirect to="/" />
        )
    }

    const handleClick = (e) => {
        let id = e.target.id.replace("image", "");
        window.sessionStorage.setItem("previousPage", props.history.location.pathname);
        props.history.push("/municipalities/" + id, { id: id });
    }

    const rows = usersForMunicipalities.map((item, index) =>
        <tr key={index}>
            <td>{item.municipalityName}</td>
            <td>{item.residentialCount}</td>
            <td>{item.commercialCount}</td>
        </tr>
    )
    
    const pictureRows = municipalities.map((item, index) =>
        <div className="imageDiv" key={index}>
            <img className="image" id={item.Id} src={item.Image}></img>
        </div>

    )

    const firstRow = pictureRows.slice(0, 5)
    const secondRow = pictureRows.slice(5, 10)
    const thirdRow = pictureRows.slice(10, 15)
    const fourthRow = pictureRows.slice(15, 20)

    return (
        <div>
            <NavBar></NavBar>
            <div id="imagesDiv" className="centerize">
                <div className="firstDivMargin" onClick={handleClick}>
                    <div className="rowDiv">
                        {firstRow}
                    </div>
                    <div className="rowDiv">
                        {secondRow}
                    </div>
                    <div className="rowDiv">
                        {thirdRow}
                    </div>
                    <div className="rowDiv">
                        {fourthRow}
                    </div>
                </div>
            </div>
            <div id="tablesDiv" className="hidenDiv">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Citoyen</th>
                            <th>Commerce</th>
                            <th>Totale</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Aujourd'hui</td>
                            <td>{residentialCountForToday}</td>
                            <td>{commercialCountForToday}</td>
                            <td>{residentialCountForToday + commercialCountForToday}</td>
                        </tr>
                        <tr>
                            <td>Mois en cours</td>
                            <td>{residentialCountForMonth}</td>
                            <td>{commercialCountForMonth}</td>
                            <td>{residentialCountForMonth + commercialCountForMonth}</td>
                        </tr>
                        <tr>
                            <td>Année en cours</td>
                            <td>{residentialCountForYear}</td>
                            <td>{commercialCountForYear}</td>
                            <td>{residentialCountForYear + commercialCountForYear}</td>
                        </tr>
                    </tbody>
                </Table>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Citoyen</th>
                            <th>Commerce</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            </div>
        </div >
    )
}

export default withRouter(Home)