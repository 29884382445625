import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { rootReducer } from './store/reducers'

const rootElement = document.getElementById('root')

const store = createStore(rootReducer)
//store.subscribe(() => console.log(store.getState()))
//store.dispatch(setUserId(3))

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement)

