export const Actions = {
    SET_USER_ID: "SET_USER_ID",
    SET_AUTH: "SET_AUTH",
    SET_EMAIL: "SET_EMAIL",
    SET_PWD: "SET_PWD",
    SET_CONFIRM_PASSWORD: "SET_CONFIRM_PASSWORD",
    SET_MUNICIPALITY: "SET_MUNICIPALITY",
    SET_RESIDENTIAL_FOR_TODAY: "SET_RESIDENTIAL_FOR_TODAY",
    SET_RESIDENTIAL_FOR_MONTH: "SET_RESIDENTIAL_FOR_MONTH",
    SET_RESIDENTIAL_FOR_YEAR: "SET_RESIDENTIAL_FOR_YEAR",
    SET_COMMERCIAL_FOR_TODAY: "SET_COMMERCIAL_FOR_TODAY",
    SET_COMMERCIAL_FOR_MONTH: "SET_COMMERCIAL_FOR_MONTH",
    SET_COMMERCIAL_FOR_YEAR: "SET_COMMERCIAL_FOR_YEAR",
    SET_USERS_FOR_MUNICIPALITIES: "SET_USERS_FOR_MUNICIPALITIES",
    SET_CURRENT_NAV_TAB: "SET_CURRENT_NAV_TAB",
    SET_START_DATE: "SET_START_DATE",
    SET_END_DATE: "SET_END_DATE",
    SET_SELECTED_MUNICIPALITY: "SET_SELECTED_MUNICIPALITY",
    SET_SELECTED_USER_TYPE: "SET_SELECTED_USER_TYPE",
    SET_TABLE_DATA: "SET_TABLE_DATA",
    SET_SHOW_MUNICIPALITIES: "SET_SHOW_MUNICIPALITIES",
    SET_USERS_LIST: "SET_USERS_LIST",
    SET_REPORT_TABLE_TOTAL_COUNTS: "SET_REPORT_TABLE_TOTAL_COUNTS",
    SET_IS_TYPE_CHANGE: "SET_IS_TYPE_CHANGE"
}