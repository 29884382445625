import React from 'react'
import Mrclogo from "../../assets/img/new/LogoMRC.jpg"
import Ridrlogo from "../../assets/img/ridr-logo-2017.jpg"
import './header.css';

function Header() {
    return (
        <div className="logoDiv">
            <div>
                <img className="logoImg" src={Mrclogo}></img>
            </div>
            <div className="logoTextDiv">
                <span className="spanSize">
                    225, Progress<br />
                        Chertsey, Quebec<br />
                        J0K 3K0
                    </span>
            </div>
            <div>
                <img className="logoImg" src={Ridrlogo}></img>
            </div>
        </div>
    )
}

export default Header