import { Actions as ActionNames } from '../constants/actionsConstants'

const setUserId = (payload) => {
    return {
        type: ActionNames.SET_USER_ID,
        payload
    }
}

const setAuth = (payload) => {
    return {
        type: ActionNames.SET_AUTH,
        payload
    }
}

const setEmail = (payload) => {
    return {
        type: ActionNames.SET_EMAIL,
        payload
    }
}

const setPWD = (payload) => {
    return {
        type: ActionNames.SET_PWD,
        payload
    }
}

const setMunicipality = (payload) => {
    return {
        type: ActionNames.SET_MUNICIPALITY,
        payload
    }
}

const setResidentialForToday = (payload) => {
    return {
        type: ActionNames.SET_RESIDENTIAL_FOR_TODAY,
        payload
    }
}

const setResidentialForMonth = (payload) => {
    return {
        type: ActionNames.SET_RESIDENTIAL_FOR_MONTH,
        payload
    }
}

const setResidentialForYear = (payload) => {
    return {
        type: ActionNames.SET_RESIDENTIAL_FOR_YEAR,
        payload
    }
}

const setCommercialForToday = (payload) => {
    return {
        type: ActionNames.SET_COMMERCIAL_FOR_TODAY,
        payload
    }
}

const setCommercialForMonth = (payload) => {
    return {
        type: ActionNames.SET_COMMERCIAL_FOR_MONTH,
        payload
    }
}

const setCommercialForYear = (payload) => {
    return {
        type: ActionNames.SET_COMMERCIAL_FOR_YEAR,
        payload
    }
}

const setUsersForMunicipalities = (payload) => {
    return {
        type: ActionNames.SET_USERS_FOR_MUNICIPALITIES,
        payload
    }
}

const setCurrentNavTab = (payload) => {
    return {
        type: ActionNames.SET_CURRENT_NAV_TAB,
        payload
    }
}

const setStartDate = (payload) => {
    return {
        type: ActionNames.SET_START_DATE,
        payload
    }
}

const setEndDate = (payload) => {
    return {
        type: ActionNames.SET_END_DATE,
        payload
    }
}

const setSelectedMunicipality = (payload) => {
    return {
        type: ActionNames.SET_SELECTED_MUNICIPALITY,
        payload
    }
}

const setSelectedUserType = (payload) => {
    return {
        type: ActionNames.SET_SELECTED_USER_TYPE,
        payload
    }
}

const setTableData = (payload) => {
    return {
        type: ActionNames.SET_TABLE_DATA,
        payload
    }
}

const setConfirmPassword = (payload) => {
    return {
        type: ActionNames.SET_CONFIRM_PASSWORD,
        payload
    }
}

const setShowMunicipalities = (payload) => {
    return {
        type: ActionNames.SET_SHOW_MUNICIPALITIES,
        payload
    }
}

const setUsersList = (payload) => {
    return {
        type: ActionNames.SET_USERS_LIST,
        payload
    }
}

const setReportTableTotalCounts = (payload) => {
    return {
        type: ActionNames.SET_REPORT_TABLE_TOTAL_COUNTS,
        payload
    }
}

const setIsTypeChange = (payload) => {
    return {
        type: ActionNames.SET_IS_TYPE_CHANGE,
        payload
    }
}

export const Actions = {
    setAuth,
    setCommercialForMonth,
    setCommercialForToday,
    setCommercialForYear,
    setConfirmPassword,
    setCurrentNavTab,
    setEmail,
    setEndDate,
    setMunicipality,
    setPWD,
    setResidentialForMonth,
    setResidentialForToday,
    setResidentialForYear,
    setSelectedMunicipality,
    setSelectedUserType,
    setShowMunicipalities,
    setStartDate,
    setTableData,
    setUserId,
    setUsersForMunicipalities,
    setUsersList,
    setReportTableTotalCounts,
    setIsTypeChange
}